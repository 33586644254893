import { TableRow, TableCell, IconButton } from "@mui/material";
import moment from "moment";
import { useState, Fragment } from "react";
import Checkbox from "../../../components/Checkbox";
import { ArrowIcon, ErrorIcon } from "../../../components/Icons";
import ToolTip from "../../../components/ToolTip";
import { useStoreValue } from "../../../store";
import { CancellationFail } from "../../../types/cancellation.type";
import { Order, SelectedOrderSKUs, SKU } from "../../../types/order.type";
import { getTenderInfomation } from "../../../utils/getTenderInfomation";
import { OrderTableSubItem } from "./orders-table";

export const MultipleSkusOrder = ({
  order,
  cancellationFailData,
  disabled,
  itemQty,
  selectedSKUs,
  handleCollectSKU,
  handleCollectSKUs,
  handleClearError,
}: {
  order: Order;
  cancellationFailData: {
    [order_id: string]: CancellationFail[];
  };
  disabled: boolean;
  itemQty: number;
  selectedSKUs: {} | SelectedOrderSKUs;
  handleCollectSKU: (order_id: string, web_sku_id: string) => void;
  handleCollectSKUs: (
    order_id: string,
    web_sku_ids: string[],
    indeterminate: boolean,
    allChecked: boolean
  ) => void;
  handleClearError: (orderId: string, webSkuId?: string) => void;
}) => {
  const [storeState, updateStore] = useStoreValue();
  const [collapseOrders, setCollapseOrders] = useState<string[]>([]);

  const handleCollapseOrders = (id: string) => {
    if (collapseOrders.includes(id)) {
      const newCollapseOrders = collapseOrders.filter(
        (orderId) => orderId !== id
      );
      setCollapseOrders(newCollapseOrders);
    } else {
      setCollapseOrders([id, ...collapseOrders]);
    }
  };

  const orderAllSkus: string[] = order.skus.reduce(
    (pre: string[], cur: SKU) => {
      return [...pre, cur.web_sku_id];
    },
    []
  );

  const orderSkusChecked: string[] =
    selectedSKUs[order.order_id as keyof typeof selectedSKUs];

  const multipleChecked = orderSkusChecked
    ? orderSkusChecked.length === orderAllSkus.length
    : false;

  const multipleIndeterminate = orderSkusChecked
    ? orderSkusChecked.length < orderAllSkus.length
    : false;

  const errorAmount =
    cancellationFailData[order.order_id] &&
    cancellationFailData[order.order_id].length;

  const objTooltipElement = (orderId: string) => (
    <div className="flex p-1">
      <div className="text-sm border-r-2 pr-2 mr-2">
        {`${errorAmount} ${errorAmount > 1 ? "Errors" : "Error"}`}
      </div>
      <div
        className="text-sm font-semibold cursor-pointer"
        onClick={() => {
          handleClearError(orderId);
        }}
      >
        Clear Error
      </div>
    </div>
  );

  const { latestRefundDate, remainingDays, tendersName } = getTenderInfomation({
    startDate: order.order_place_date,
    tenders: storeState.tendersData,
    tenderTypes: order.tenders,
  });

  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            size="small"
            className={`${
              collapseOrders.includes(order.order_id) ? "rotate-90" : ""
            }`}
            onClick={() => handleCollapseOrders(order.order_id)}
          >
            <ArrowIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <Checkbox
            disabled={disabled}
            checked={multipleChecked}
            indeterminate={multipleIndeterminate}
            onChange={() =>
              handleCollectSKUs(
                order.order_id,
                orderAllSkus,
                multipleIndeterminate,
                multipleChecked
              )
            }
          />
        </TableCell>
        <TableCell align="left">
          <div className="flex items-center">
            {cancellationFailData[order.order_id] && (
              <ToolTip title={objTooltipElement(order.order_id)}>
                <div className="pr-4">
                  <ErrorIcon />
                </div>
              </ToolTip>
            )}

            <div>{order.order_id}</div>
          </div>
        </TableCell>
        <TableCell align="left">
          {moment(new Date(order.order_place_date)).format("DD MMM, yyyy")}
        </TableCell>
        <TableCell align="left">{`${order.skus.length} SKUs`}</TableCell>
        <TableCell align="right">{itemQty}</TableCell>
        <TableCell align="right">{tendersName?.join(", ")}</TableCell>
        <TableCell align="right">{latestRefundDate}</TableCell>
        <TableCell align="right">{remainingDays}</TableCell>
      </TableRow>

      {order.skus.length > 1 &&
        collapseOrders.includes(order.order_id) &&
        order.skus.map((sku: SKU, key: number) => {
          const hasSkus: string[] =
            selectedSKUs[order.order_id as keyof typeof selectedSKUs];

          const isChecked = hasSkus ? hasSkus.includes(sku.web_sku_id) : false;

          const hasError =
            cancellationFailData[order?.order_id] &&
            cancellationFailData[order?.order_id].find(
              (item) => item.web_sku_id === sku?.web_sku_id
            );

          const tooltipElement = (orderId: string, webSkuId: string) => (
            <div className="flex p-1">
              <div className="text-sm border-r-2 pr-2 mr-2">
                {hasError?.message}
              </div>
              <div
                className="text-sm font-semibold cursor-pointer"
                onClick={() => handleClearError(orderId, webSkuId)}
              >
                Clear Error
              </div>
            </div>
          );

          return (
            <OrderTableSubItem key={key}>
              <TableCell></TableCell>
              <TableCell>
                <Checkbox
                  disabled={disabled}
                  checked={isChecked}
                  onChange={() =>
                    handleCollectSKU(order.order_id, sku.web_sku_id)
                  }
                />
              </TableCell>
              <TableCell align="left">
                <div className="flex items-center">
                  {hasError?.web_sku_id === sku.web_sku_id && (
                    <ToolTip
                      title={tooltipElement(order.order_id, sku.web_sku_id)}
                    >
                      <div className="pr-4">
                        <ErrorIcon />
                      </div>
                    </ToolTip>
                  )}

                  <div>{order.order_id}</div>
                </div>
              </TableCell>
              <TableCell align="left">
                {moment(new Date(order.order_place_date)).format(
                  "DD MMM, yyyy"
                )}
              </TableCell>
              <TableCell align="left">{sku.web_sku_id}</TableCell>
              <TableCell align="right">{sku.quantity}</TableCell>
              <TableCell align="right">{tendersName?.join(", ")}</TableCell>
              <TableCell align="right">{latestRefundDate}</TableCell>
              <TableCell align="right">{remainingDays}</TableCell>
            </OrderTableSubItem>
          );
        })}
    </Fragment>
  );
};
